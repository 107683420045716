import { useEffect, useRef, useState } from "react";
var DEFAULT_TIMING = 1000;
var usePollCallback = function (callback, timing) {
    if (timing === void 0) { timing = DEFAULT_TIMING; }
    var savedCallback = useRef(callback);
    var _a = useState(false), isRunning = _a[0], setIsRunning = _a[1];
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(function () {
        if (!isRunning)
            return;
        var tick = function () { return savedCallback.current(); };
        var id = window.setInterval(tick, timing);
        return function () { return window.clearInterval(id); };
    }, [isRunning, timing]);
    return [function () { return setIsRunning(true); }, function () { return setIsRunning(false); }];
};
export default usePollCallback;
