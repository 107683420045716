var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as CKModal } from "@cheetahdigital/console-kit-cx";
import { Layout } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import Clickable from "../../elements/Clickable";
import Headline from "../../elements/Headline";
import styles from "./index.module.scss";
var Modal = function (_a) {
    var name = _a.name, title = _a.title, trigger = _a.trigger, isActive = _a.isActive, isScrollable = _a.isScrollable, isFullscreen = _a.isFullscreen, _b = _a.isClickable, isClickable = _b === void 0 ? true : _b, onOpen = _a.onOpen, onClose = _a.onClose, children = _a.children;
    return (_jsx(CKModal, __assign({ name: name, isSolid: true, trigger: isClickable ? _jsx(Clickable, { children: trigger }) : _jsx("span", { children: trigger }), isTransparent: true, hasCloseIcon: true, isFullscreen: isFullscreen, showModal: isActive, onClose: onClose, onOpen: onOpen }, { children: _jsxs("div", __assign({ className: styles.modalContainer }, { children: [title && (_jsx(Layout, { children: _jsx(Layout.Row, { children: _jsx(Headline, __assign({ size: "huge", isUppercase: false, isGapless: true }, { children: title })) }) })), _jsx("div", __assign({ className: classNames(isScrollable && styles.isScrollable) }, { children: children }))] })) })));
};
export default Modal;
