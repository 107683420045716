var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button as CKButton } from "@cheetahdigital/console-kit-cx";
var Button = function (_a) {
    var _b = _a.isFluid, isFluid = _b === void 0 ? false : _b, _c = _a.isSolid, isSolid = _c === void 0 ? true : _c, _d = _a.isPlain, isPlain = _d === void 0 ? false : _d, _e = _a.isLoading, isLoading = _e === void 0 ? false : _e, _f = _a.color, color = _f === void 0 ? "accent" : _f, _g = _a.type, type = _g === void 0 ? "primary" : _g, fontFamily = _a.fontFamily, isDisabled = _a.isDisabled, _h = _a.isUppercase, isUppercase = _h === void 0 ? true : _h, isPaddingLess = _a.isPaddingLess, rightIcon = _a.rightIcon, _j = _a.onClick, onClick = _j === void 0 ? function () { return ({}); } : _j, size = _a.size, children = _a.children;
    var icon = rightIcon && {
        content: rightIcon,
        position: "right"
    };
    var buttonProps = {
        text: children,
        isFluid: isFluid,
        isSolid: isSolid,
        isDisabled: isDisabled || isLoading,
        onClick: onClick,
        color: color,
        icon: icon,
        _display: (isPlain && "plain") || (isSolid && "solid") || "outline",
        buttonState: isLoading ? "loading" : undefined,
        size: size,
        font: {
            emphasis: isUppercase ? "caps" : "normal",
            family: fontFamily
        },
        style: { padding: isPaddingLess && 0 }
    };
    return (_jsxs(_Fragment, { children: [type === "primary" && (_jsx(CKButton.Primary, __assign({ isInverted: !isLoading }, buttonProps))), type === "secondary" && (_jsx(CKButton.Secondary, __assign({ isInverted: true }, buttonProps)))] }));
};
export default Button;
