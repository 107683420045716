var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "shared/src/components/Captcha/index.module.scss";
import Clickable from "../../elements/Clickable";
import Icon from "../../elements/Icon";
var CONFIG = {
    font: "italic 70px bold Arial Black",
    text: "#91919191",
    lines: "#91919191",
    dots: "#cacaca"
};
var alphanumeric = "abcdefghijklmnopqrstuvwxyz0123456789";
var Captcha = function (props, ref) {
    var canvasRef = useRef(null);
    var _a = useState(""), generatedString = _a[0], setGeneratedString = _a[1];
    var getRandomInt = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    var generateRandomString = useCallback(function (length) {
        var result = Array.from({ length: length }, function () {
            return alphanumeric.charAt(getRandomInt(0, alphanumeric.length - 1));
        }).join("");
        setGeneratedString(result);
        return result;
    }, []);
    var getRandomPositions = useCallback(function (count, width, height) {
        var margin = 20;
        var spacing = (width - 2 * margin) / count;
        return Array.from({ length: count }, function (_, i) { return ({
            x: getRandomInt(margin + i * spacing, margin + (i + 1) * spacing - 40),
            y: getRandomInt(margin + 20, height - margin)
        }); });
    }, []);
    var clearCanvas = function (ctx) {
        if (canvasRef.current) {
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        }
    };
    var drawRandomLines = useCallback(function (ctx, count) {
        ctx.strokeStyle = CONFIG.lines;
        ctx.lineWidth = getRandomInt(1, 1);
        for (var i = 0; i < count; i++) {
            ctx.beginPath();
            ctx.moveTo(getRandomInt(0, ctx.canvas.width), getRandomInt(0, ctx.canvas.height));
            ctx.lineTo(getRandomInt(0, ctx.canvas.width), getRandomInt(0, ctx.canvas.height));
            ctx.stroke();
        }
    }, []);
    var drawRandomDots = useCallback(function (ctx, count) {
        ctx.fillStyle = CONFIG.dots;
        for (var i = 0; i < count; i++) {
            ctx.beginPath();
            ctx.arc(getRandomInt(0, ctx.canvas.width), getRandomInt(0, ctx.canvas.height), getRandomInt(1, 3), 0, Math.PI * 2);
            ctx.fill();
        }
    }, []);
    var drawCaptcha = useCallback(function (ctx, text, positions) {
        ctx.font = CONFIG.font;
        ctx.fillStyle = CONFIG.text;
        text.split("").forEach(function (char, i) {
            ctx.save();
            ctx.translate(positions[i].x, positions[i].y);
            var angle = ((Math.random() - 0.5) * Math.PI) / 4;
            ctx.rotate(angle);
            ctx.fillText(char, 0, 0);
            ctx.restore();
        });
    }, []);
    var drawObfuscationLines = useCallback(function (ctx, positions) {
        ctx.strokeStyle = CONFIG.lines;
        ctx.lineWidth = 3;
        ctx.beginPath();
        positions.forEach(function (pos, i) {
            if (i < positions.length - 1) {
                ctx.moveTo(pos.x, pos.y - 15);
                ctx.lineTo(positions[i + 1].x + 10, positions[i + 1].y - 15);
            }
        });
        ctx.stroke();
    }, []);
    var resetCanvas = useCallback(function (ctx) {
        var randomString = generateRandomString(5);
        var positions = getRandomPositions(5, ctx.canvas.width, ctx.canvas.height);
        clearCanvas(ctx);
        drawRandomLines(ctx, 5);
        drawRandomDots(ctx, 500);
        drawCaptcha(ctx, randomString, positions);
        drawObfuscationLines(ctx, positions);
    }, [
        drawCaptcha,
        drawObfuscationLines,
        drawRandomDots,
        drawRandomLines,
        generateRandomString,
        getRandomPositions
    ]);
    useEffect(function () {
        var canvas = canvasRef.current;
        if (canvas) {
            var ctx = canvas.getContext("2d");
            if (ctx) {
                resetCanvas(ctx);
            }
        }
    }, [resetCanvas]);
    var refresh = useCallback(function () {
        var _a;
        resetCanvas((_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.getContext("2d"));
    }, [resetCanvas]);
    useEffect(function () {
        props.onChange(generatedString);
    }, [generatedString, props]);
    useImperativeHandle(ref, function () { return ({
        refresh: refresh
    }); });
    return (_jsxs("div", __assign({ className: styles.div }, { children: [_jsx("canvas", { ref: canvasRef, width: "300", height: "100", id: "canvas" }), _jsx(Clickable, __assign({ onClick: refresh }, { children: _jsx("span", __assign({ className: styles.refresh }, { children: _jsx(Icon, { name: "refresh", size: "small", color: "white" }) })) }))] })));
};
export default forwardRef(Captcha);
