import { useHistory, useLocation } from "react-router-dom";
var useQueryParam = function () {
    var history = useHistory();
    var location = useLocation();
    function removeQueryParam(paramName) {
        var searchParams = new URLSearchParams(location.search);
        searchParams.delete(paramName);
        var newSearch = searchParams.toString();
        var newPathname = location.pathname;
        history.replace({
            pathname: newPathname,
            search: newSearch
        });
    }
    return { removeQueryParam: removeQueryParam };
};
export default useQueryParam;
