var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip as CkTooltip } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import styles from "./index.module.scss";
var Tooltip = function (_a) {
    var isCk = _a.isCk, content = _a.content, children = _a.children, _b = _a.position, position = _b === void 0 ? "right center" : _b, _c = _a.isInline, isInline = _c === void 0 ? false : _c, isDisabled = _a.isDisabled, isGapless = _a.isGapless, isBreakAll = _a.isBreakAll, color = _a.color, link = _a.link, icon = _a.icon, isEllipsis = _a.isEllipsis, className = _a.className;
    var popupStyle = {
        borderRadius: "3px",
        padding: ".625rem 2rem",
        background: "#4A4A4A",
        fontSize: "1.1rem",
        color: "#FFFFFF",
        lineHeight: "1.5rem"
    };
    var triggerStyle = {
        display: isInline ? "inline-flex" : "block",
        padding: isGapless ? "none" : ".25rem"
    };
    var isDefault = !link && !icon && !isEllipsis;
    return (_jsxs(_Fragment, { children: [!isCk && !content && _jsx(_Fragment, { children: children }), !isCk && content && (_jsx(Popup, { content: content, trigger: _jsxs("div", __assign({ style: triggerStyle }, { children: [!isDefault && (_jsxs(_Fragment, { children: [link && (_jsxs(Link, __assign({ className: styles.tooltipContent, to: link, target: "_blank" }, { children: [icon && _jsx(_Fragment, { children: icon }), _jsx("b", __assign({ className: classNames(isEllipsis && styles.isEllipsis) }, { children: children }))] }))), !link && (_jsxs("div", __assign({ className: styles.tooltipContent }, { children: [icon && _jsx(_Fragment, { children: icon }), _jsx("div", __assign({ className: classNames(isEllipsis && styles.isEllipsis) }, { children: children }))] })))] })), isDefault && children] })), style: popupStyle, className: classNames(styles.popup, "ck-popup-menu-parent", isBreakAll && styles.isBreakAll, className), disabled: isDisabled, position: position })), isCk && (_jsx(CkTooltip, __assign({ color: color, tooltip: content, position: position, isInline: false, isCompact: true, isMultiLine: true }, { children: children })))] }));
};
export default Tooltip;
