var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useState } from "react";
var useDatatable = function (_a) {
    var _b = _a === void 0 ? {} : _a, sort = _b.sort, _c = _b.pageSize, pageSize = _c === void 0 ? 20 : _c;
    var _d = useState({
        page: 0,
        sort: sort,
        query: "",
        pageSize: pageSize || 20,
        startIndex: 0,
        selectedItems: [],
        items: [],
        triggerFetch: false
    }), state = _d[0], setState = _d[1];
    var setPage = useCallback(function (page) {
        setState(function (prev) { return (__assign(__assign({}, prev), { page: page, startIndex: page * pageSize })); });
    }, [pageSize]);
    var setItems = useCallback(function (items) {
        setState(function (prev) { return (__assign(__assign({}, prev), { items: items })); });
    }, []);
    var setSelectedItem = useCallback(function (item) {
        var isExist = state.selectedItems.some(function (selectedItem) { return selectedItem.key === item.key; });
        if (isExist) {
            setState(function (prev) { return (__assign(__assign({}, prev), { selectedItems: state.selectedItems.filter(function (selectedItem) { return selectedItem.key !== item.key; }) })); });
            return;
        }
        setState(function (prev) { return (__assign(__assign({}, prev), { selectedItems: __spreadArray(__spreadArray([], state.selectedItems, true), [item], false) })); });
    }, [state]);
    var setAllSelectedItems = useCallback(function (isSelectAll, items) {
        var selectedItems = state.selectedItems;
        var filteredItems = items === null || items === void 0 ? void 0 : items.filter(function (item) { return !(item === null || item === void 0 ? void 0 : item.isDisabled); });
        if (isSelectAll) {
            setState(function (prev) { return (__assign(__assign({}, prev), { selectedItems: __spreadArray(__spreadArray([], selectedItems, true), filteredItems, true) })); });
            return;
        }
        var filteredSelectedItems = selectedItems.filter(function (selectedItem) { return !items.some(function (item) { return item.key === selectedItem.key; }); });
        setState(function (prev) { return (__assign(__assign({}, prev), { selectedItems: filteredSelectedItems })); });
    }, [state]);
    var clearAllSelectedItems = useCallback(function () {
        setState(function (prev) { return (__assign(__assign({}, prev), { selectedItems: [] })); });
    }, []);
    var reset = useCallback(function (sort, triggerFetch) {
        setState({
            page: 0,
            query: "",
            pageSize: pageSize || 20,
            startIndex: 0,
            selectedItems: [],
            items: [],
            sort: sort,
            triggerFetch: !triggerFetch
        });
    }, [pageSize]);
    var setQuery = useCallback(function (query) {
        setState(function (prev) {
            return __assign(__assign({}, prev), { query: query, page: query !== prev.query ? 0 : prev.page });
        });
    }, []);
    var setSort = useCallback(function (sort) {
        setState(function (prev) {
            return __assign(__assign({}, prev), { sort: sort });
        });
    }, []);
    return __assign(__assign({}, state), { setPage: setPage, setSort: setSort, setItems: setItems, setSelectedItem: setSelectedItem, setAllSelectedItems: setAllSelectedItems, clearAllSelectedItems: clearAllSelectedItems, setQuery: setQuery, reset: reset });
};
export default useDatatable;
