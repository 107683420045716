import { FilterItems } from "components/shared/Filter/types";
import type { ListingFilter } from "components/types";

import { SearchParam } from "./index";

export const getRememberedFilterValues = (
  filters: SearchParam["filters"],
  field: string
): ListingFilter["values"] => {
  if (!filters) return [];
  const item = filters.filter(item => item.field === field)[0];
  return item?.values || [];
};

export const mapRememberedFilter = (
  items: FilterItems,
  values: ListingFilter["values"]
): FilterItems => {
  if (!values.length) return items;
  return items?.map(item => {
    if (item.isSelected) return item;
    return {
      ...item,
      isSelected: values.indexOf(item.value) >= 0
    };
  });
};

export const mapRememberedPayload = (
  selected: SearchParam,
  remembered: SearchParam
): {
  q?: string;
  filters?: ListingFilter[];
} => {
  const { query, filters } = selected;
  const q = query || remembered?.query || "";

  if (filters && filters?.length) {
    return {
      q,
      filters
    };
  }
  return {
    q,
    filters: remembered?.filters || []
  };
};

export const mapStringToNumber = (arr: (string | number | Date)[]): number[] =>
  arr.map(v => Number(v));

export const mapValueToNumber = (items: FilterItems): FilterItems =>
  (items || []).map(item => ({
    ...item,
    value: Number(item.value)
  }));
