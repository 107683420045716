import { Cookie } from "shared";
import languages, { Language } from "utils/constants/languages";
import locales, { Locale as LocaleType } from "utils/constants/locales";
import { Timezone } from "utils/constants/timezones";

export type Locale = {
  language: Language["value"];
  locale: LocaleType["value"];
  timezone?: Timezone["value"];
};

const DEFAULT_LOCALE: Locale = {
  language: "en",
  locale: "en_US",
  timezone: undefined
};

export const getLocale = (): Locale => {
  try {
    return JSON.parse(Cookie.getCookie("cid_locale") || "{}");
  } catch {
    return {} as Locale;
  }
};

export const getClientLocale = (): string => {
  const clientLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  const locale = locales.find(
    loc => loc.value.slice(0, 2) === clientLocale.slice(0, 2)
  );

  if (locale) {
    return locale.value;
  }

  return "en_US";
};

export const getClientLanguage = (): string => {
  if (languages.find(({ value }) => value === navigator.language.slice(0, 2))) {
    return navigator.language.slice(0, 2);
  }

  return "en";
};

const useLocale = (): Locale => {
  const locale = getLocale();

  return Object.keys(DEFAULT_LOCALE).reduce((acc, key) => {
    const isValidValue = !!locale[key] && locale[key] !== "null";
    acc[key] = isValidValue ? locale[key] : DEFAULT_LOCALE[key];

    return acc;
  }, {} as Locale);
};

export default useLocale;
