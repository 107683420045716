var appendParams = function (host, params) {
    var url = new URL(host);
    for (var _i = 0, _a = Object.entries(params); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (value) {
            url.searchParams.append(key, String(value));
        }
    }
    return url.href;
};
export default {
    appendParams: appendParams
};
