import { jsx as _jsx } from "react/jsx-runtime";
import { Toggle as CKSwitch, enums } from "@cheetahdigital/console-kit-cx";
var Switch = function (_a) {
    var label = _a.label, checked = _a.checked, isDisabled = _a.isDisabled, onChange = _a.onChange;
    return (_jsx(CKSwitch, { isOn: checked, isDisabled: isDisabled, checkSize: enums.sizes.X4, onChange: function (event, _a) {
            var checked = _a.checked;
            if (onChange) {
                onChange(checked);
            }
        }, label: label, gutterSize: "small" }));
};
export default Switch;
