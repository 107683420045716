import type { ListingFilter } from "components/types";
import { createContext, FC, useState, useContext, useCallback } from "react";

export type SearchParam = {
  path?: string;
  query?: string;
  filters?: ListingFilter[];
};
export const INITIAL_STORAGE: SearchParam = {
  path: "",
  query: "",
  filters: []
};

type Context = {
  remembered: SearchParam;
  setQuery: (value: string) => void;
  setFilter: (filter: ListingFilter) => void;
  clearAll: () => void;
};

export const RememberSearchContext = createContext<Context>({
  remembered: INITIAL_STORAGE,
  setQuery: () => ({}),
  setFilter: () => ({}),
  clearAll: () => ({})
});

const RememberSearchProvider: FC = ({ children }) => {
  const [remembered, setRemembered] = useState<SearchParam>(INITIAL_STORAGE);

  const setQuery = useCallback((query: string) => {
    setRemembered(rmb => {
      const path = window.location.pathname;

      return {
        ...rmb,
        path,
        query
      };
    });
  }, []);

  const setFilter = useCallback(({ field, values }: ListingFilter) => {
    setRemembered(rmb => {
      const path = window.location.pathname;
      const filters = rmb.filters || [];
      const filtered = filters.filter(item => item.field !== field);
      const item = !values || !values.length ? [] : [{ field, values }];

      return {
        ...rmb,
        path,
        filters: [...filtered, ...item]
      };
    });
  }, []);

  const clearAll = useCallback(() => {
    setRemembered(INITIAL_STORAGE);
  }, []);

  const context = {
    remembered,
    setQuery,
    setFilter,
    clearAll
  };

  return (
    <RememberSearchContext.Provider value={context}>
      {children}
    </RememberSearchContext.Provider>
  );
};

export const useRememberSearchContext = (): Context => {
  return useContext(RememberSearchContext);
};

export * from "./utils";
export default RememberSearchProvider;
