var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useEffect, useRef, useState } from "react";
export function usePromise(promise, mutation, options) {
    if (options === void 0) { options = {}; }
    var _a = useState({
        isSuccess: false,
        isLoading: false,
        state: "idle",
        result: undefined,
        error: undefined
    }), state = _a[0], setState = _a[1];
    var currentRef = useRef();
    var promiseRef = useRef(promise);
    var optionsRef = useRef(options);
    var execute = useCallback(function (props) {
        var currentPromiseRef = promiseRef === null || promiseRef === void 0 ? void 0 : promiseRef.current(props);
        setState(function (prev) {
            if (prev.state !== "pending" && currentPromiseRef) {
                return {
                    isLoading: true,
                    isSuccess: false,
                    state: "pending",
                    result: prev.result,
                    error: undefined
                };
            }
            return prev;
        });
        var currentPromise = currentPromiseRef === null || currentPromiseRef === void 0 ? void 0 : currentPromiseRef.then(function (result) {
            var _a, _b;
            if (currentPromise === currentRef.current) {
                (_b = (_a = optionsRef.current).onSuccess) === null || _b === void 0 ? void 0 : _b.call(_a, result);
                setState({
                    state: "resolved",
                    isLoading: false,
                    isSuccess: true,
                    error: undefined,
                    result: result
                });
            }
        }).catch(function (error) {
            var _a, _b;
            if (currentPromise === currentRef.current) {
                (_b = (_a = optionsRef.current).onError) === null || _b === void 0 ? void 0 : _b.call(_a, error);
                setState({
                    state: "rejected",
                    isLoading: false,
                    isSuccess: false,
                    result: undefined,
                    error: error
                });
            }
        });
        currentRef.current = currentPromise;
    }, []);
    var reset = useCallback(function () {
        setState({
            state: "idle",
            isLoading: false,
            isSuccess: false,
            error: undefined,
            result: undefined
        });
    }, []);
    useEffect(function () {
        promiseRef.current = promise;
    });
    useEffect(function () {
        if (mutation) {
            execute();
        }
    }, [execute, mutation]);
    return [
        __assign(__assign({}, state), { reset: reset }),
        execute
    ];
}
export default usePromise;
