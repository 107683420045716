var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Checkbox as CKCheckbox } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import styles from "./index.module.scss";
var Checkbox = function (_a) {
    var label = _a.label, value = _a.value, checked = _a.checked, onChange = _a.onChange, disabled = _a.disabled, isModified = _a.isModified;
    return (_jsx("span", __assign({ className: classNames(styles.checkbox, isModified && styles.isModified, !label && styles.isNoLabel) }, { children: _jsx(CKCheckbox, { isDisabled: disabled, value: value, label: label, gutterSize: "small", isChecked: checked, onChange: function (event, value) {
                onChange({
                    value: value.value,
                    isChecked: value.checked
                });
            } }) })));
};
export default Checkbox;
