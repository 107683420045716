var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Input as CKInput } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import { useCallback } from "react";
import styles from "./index.module.scss";
var Input = function (_a) {
    var id = _a.id, name = _a.name, autoComplete = _a.autoComplete, label = _a.label, value = _a.value, type = _a.type, error = _a.error, icon = _a.icon, onChange = _a.onChange, onBlur = _a.onBlur, placeholder = _a.placeholder, disabled = _a.disabled, isPointerEventsAuto = _a.isPointerEventsAuto, _b = _a.maxLength, maxLength = _b === void 0 ? 0 : _b, innerRef = _a.innerRef;
    var handleChange = useCallback(function (event) {
        var _a;
        if (!onChange) {
            return;
        }
        if (maxLength) {
            var newEvent = __assign(__assign({}, event), { target: __assign(__assign({}, event.target), { value: (_a = event.target.value) === null || _a === void 0 ? void 0 : _a.slice(0, maxLength) }) });
            onChange(newEvent);
            return;
        }
        onChange(event);
    }, [maxLength, onChange]);
    return (_jsx("div", __assign({ className: classNames(styles.input, isPointerEventsAuto && styles.isPointerEventsAuto) }, { children: _jsx(CKInput, { autoComplete: autoComplete, id: id, name: name, isDisabled: disabled, placeholder: placeholder, label: label, value: value, type: type, icon: icon, isFluid: true, hasError: !!error, errorMessage: error ? error : "", onChange: handleChange, onBlur: onBlur, innerRef: innerRef }) })));
};
export default Input;
