import { DefaultNS, useTranslation } from "locales/i18n";
import { FC, useCallback } from "react";
import Msg from "shared/dist/components/Message";

import { useMessageContext } from "./MessageProvider";

const Message: FC = () => {
  const [t] = useTranslation();

  const { message, closeMessage } = useMessageContext();

  const handleClose = useCallback(() => {
    closeMessage();
  }, [closeMessage]);

  return (
    <>
      {message && (
        <Msg
          title={t(message.type as DefaultNS).toUpperCase()}
          description={message.description}
          isShow={true}
          type={message.type}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default Message;
