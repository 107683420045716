import { pipe, split, map, find } from "ramda";
var setCookie = function (name, value, days) {
    if (value === void 0) { value = ""; }
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = "; expires=" + date.toUTCString();
        document.cookie = "".concat(name, "=").concat(value).concat(expires, "; path=/");
        return;
    }
    document.cookie = "".concat(name, "=").concat(value, "; path=/");
};
var getCookie = function (name) {
    var cookieName = "".concat(name, "=");
    var getCookieValue = function (value) {
        return value === null || value === void 0 ? void 0 : value.substring(cookieName.length, value.length);
    };
    return pipe(split(";"), map(function (cookie) { return cookie.trim(); }), find(function (cookie) { return cookie.indexOf(cookieName) === 0; }), getCookieValue)(document.cookie);
};
export default {
    setCookie: setCookie,
    getCookie: getCookie
};
