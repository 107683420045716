var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextArea as CKTextArea } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import { useCallback } from "react";
import styles from "./index.module.scss";
var Textarea = function (_a) {
    var placeholder = _a.placeholder, value = _a.value, isDisabled = _a.isDisabled, isNumber = _a.isNumber, error = _a.error, onChange = _a.onChange, maxLength = _a.maxLength;
    var handleChange = useCallback(function (event) {
        var _a;
        if (!onChange) {
            return;
        }
        if (isNumber && !/^[0-9\b]+$|^$/.test(event.target.value)) {
            return;
        }
        if (maxLength) {
            onChange(__assign(__assign({}, event), { target: __assign(__assign({}, event.target), { value: (_a = event.target.value) === null || _a === void 0 ? void 0 : _a.slice(0, maxLength) }) }));
        }
        onChange(event);
    }, [maxLength, onChange, isNumber]);
    return (_jsx("div", __assign({ className: classNames(styles.textarea) }, { children: _jsx(CKTextArea, { value: value, placeholder: placeholder, onChange: handleChange, isFluid: true, isDisabled: isDisabled, hasError: !!error, errorMessage: error ? error : "" }) })));
};
export default Textarea;
