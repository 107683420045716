import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { enUS, de, es, fr, ja, pt } from "date-fns/locale";
import { useCallback } from "react";
var LOCALES = [
    { locale: "de", dateFnsLocale: de },
    { locale: "en", dateFnsLocale: enUS },
    { locale: "es", dateFnsLocale: es },
    { locale: "fr", dateFnsLocale: fr },
    { locale: "ja", dateFnsLocale: ja },
    { locale: "pt", dateFnsLocale: pt }
];
var convertToShortDate = function (distanceToNow) {
    var splitted = distanceToNow.split(" ");
    var num = splitted[splitted.length - 2];
    var unit = splitted[splitted.length - 1].charAt(0);
    var value = "".concat(num).concat(unit);
    if (value === "am") {
        return "0m";
    }
    return value;
};
var DistanceToNow = function (_a) {
    var date = _a.date, _b = _a.isShort, isShort = _b === void 0 ? false : _b, _c = _a.isAddSuffix, isAddSuffix = _c === void 0 ? true : _c, _d = _a.invalidDateLabel, invalidDateLabel = _d === void 0 ? "Never" : _d, _e = _a.locale, locale = _e === void 0 ? "en" : _e;
    var getDiplay = useCallback(function () {
        if (!date) {
            return invalidDateLabel;
        }
        try {
            var dateFnsLocale = LOCALES.find(function (l) { return l.locale === locale; });
            var distanceToNow = formatDistanceToNow(new Date(date), {
                locale: (dateFnsLocale === null || dateFnsLocale === void 0 ? void 0 : dateFnsLocale.dateFnsLocale) || enUS,
                addSuffix: isShort ? false : isAddSuffix
            });
            return isShort ? convertToShortDate(distanceToNow) : distanceToNow;
        }
        catch (_a) {
            return invalidDateLabel;
        }
    }, [date, isAddSuffix, isShort, locale, invalidDateLabel]);
    return _jsx(_Fragment, { children: getDiplay() });
};
export default DistanceToNow;
