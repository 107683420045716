var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SingleSelect as CKSingleSelect } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import styles from "./index.module.scss";
var SingleSelect = function (_a) {
    var label = _a.label, value = _a.value, options = _a.options, error = _a.error, placeholder = _a.placeholder, disabled = _a.disabled, _b = _a.isSearchable, isSearchable = _b === void 0 ? false : _b, isClearable = _a.isClearable, onSearchChange = _a.onSearchChange, onChange = _a.onChange;
    return (_jsx("div", __assign({ className: classNames(styles.singleSelect, isSearchable && styles.isSearchable) }, { children: _jsx(CKSingleSelect, { label: label, value: value, options: options, placeholder: placeholder, search: isSearchable, isDisabled: disabled, isError: !!error, errorMessage: error, isFluid: true, showClearIcon: isClearable, onChange: function (event, data) {
                onChange(data === null || data === void 0 ? void 0 : data.value);
            }, onSearchChange: onSearchChange }) })));
};
export default SingleSelect;
