import { Layout } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import { FC, HTMLAttributes, useContext } from "react";
import { Card, Loading, Label, Image } from "shared";

import AuthContainerProvider, { AuthContainerContext } from "./context";
import styles from "./index.module.scss";

type AuthContainerType = HTMLAttributes<HTMLDivElement> & {
  isLoading?: boolean;
  isScrollable?: boolean;
  size?: "medium";
  isShowPrivacyLink?: boolean;
};

const AuthContainerContent: FC<AuthContainerType> = ({
  children,
  isScrollable = false,
  isLoading: isUiLoading,
  className,
  size,
  style,
  isShowPrivacyLink
}) => {
  const [isContextLoading] = useContext(AuthContainerContext);
  const isLoading = isUiLoading || isContextLoading;

  return (
    <div
      className={classNames(
        styles.container,
        isScrollable && styles.isScrollable,
        className,
        classNames(className, size && `is-${size}`)
      )}
      style={style}
    >
      <Card>
        <Layout>
          <Layout.Row>
            <Image
              src="/assets/images/logo-cheetah.svg"
              height="60"
              width="300"
            />
          </Layout.Row>
        </Layout>

        {!isLoading && children}
        {isLoading && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </Card>
      <div className={classNames("copyright", styles.copyright)}>
        <Label>
          {`©${new Date().getFullYear()} Marigold. All rights reserved.`}
          {isShowPrivacyLink && (
            <>
              {" | "}
              <a
                href="https://meetmarigold.com/privacy-notices/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy
              </a>
            </>
          )}
        </Label>
      </div>
    </div>
  );
};

const AuthContainer: FC<AuthContainerType> = ({
  children,
  isScrollable = false,
  isShowPrivacyLink = false,
  isLoading,
  className,
  size,
  style
}) => {
  return (
    <AuthContainerProvider>
      <AuthContainerContent
        isLoading={isLoading}
        isScrollable={isScrollable}
        isShowPrivacyLink={isShowPrivacyLink}
        className={className}
        style={style}
        size={size}
      >
        {children}
      </AuthContainerContent>
    </AuthContainerProvider>
  );
};

export default AuthContainer;
