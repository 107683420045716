var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import Tooltip from "../../components/Tooltip";
import Clickable from "../../elements/Clickable";
import Label from "../../elements/Label";
import EyeIcon from "./EyeIcon";
import styles from "./index.module.scss";
var DEBOUNCE_TIMING = 500;
var DebouncedInput = function (_a) {
    var id = _a.id, name = _a.name, autoComplete = _a.autoComplete, label = _a.label, value = _a.value, type = _a.type, error = _a.error, icon = _a.icon, timing = _a.timing, hasPreview = _a.hasPreview, _b = _a.onChange, onChange = _b === void 0 ? function () { return ({}); } : _b, _c = _a.onBlur, onBlur = _c === void 0 ? function () { return ({}); } : _c, _d = _a.onClick, onClick = _d === void 0 ? function () { return ({}); } : _d, placeholder = _a.placeholder, disabled = _a.disabled, i18n = _a.i18n;
    var _e = useState(value || ""), inputValue = _e[0], setInputValue = _e[1];
    var _f = useState(type), inputType = _f[0], setInputType = _f[1];
    var _g = useState(), timer = _g[0], setTimer = _g[1];
    var togglePreview = useCallback(function (isShowing) {
        setInputType(isShowing ? "text" : "password");
    }, []);
    var handleClick = useCallback(function (e) {
        e.preventDefault();
        onClick(inputValue);
    }, [inputValue, onClick]);
    var handleChange = useCallback(function (e) {
        var value = e.target.value;
        setInputValue(value);
        window.clearTimeout(timer);
        var newTimer = window.setTimeout(function () {
            onChange(value);
        }, timing || DEBOUNCE_TIMING);
        setTimer(newTimer);
    }, [onChange, timer, timing]);
    var handleKeyDown = useCallback(function (e) {
        if (e.key !== "Enter") {
            return;
        }
        var value = e.target.value;
        setInputValue(value);
        onChange(value);
    }, [onChange]);
    var inputRef = useRef(null);
    useEffect(function () {
        if (!inputRef.current)
            return;
        inputRef.current.value = value || "";
    }, [value]);
    return (_jsx("div", __assign({ className: styles.debouncedInput }, { children: _jsxs("div", __assign({ className: classNames("ck input fluid has-error-icon", !!error && "error") }, { children: [label && _jsx(Label, { children: label }), _jsxs("div", __assign({ className: "error-wrapper" }, { children: [_jsxs("div", __assign({ className: "ui large fluid input" }, { children: [_jsx("input", { autoComplete: autoComplete, id: id, name: name, defaultValue: value, type: inputType, disabled: disabled, placeholder: placeholder, onKeyDown: handleKeyDown, onChange: handleChange, onBlur: onBlur, ref: inputRef }), type !== "password" && icon && (_jsx(Clickable, __assign({ onClick: handleClick }, { children: _jsx("div", __assign({ className: styles.actionIcon }, { children: _jsx(Icon, { size: "medium", color: "base", name: icon }) })) }))), type === "password" && hasPreview && (_jsx(EyeIcon, { onClick: togglePreview, i18n: i18n }))] })), _jsx(Tooltip, __assign({ isCk: true, content: error || "", position: "top left" }, { children: _jsx(Icon, { size: "big", color: "error", name: "warning" }) }))] }))] })) })));
};
export default DebouncedInput;
