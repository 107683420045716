export type Language = {
  text: string;
  value: "de" | "en" | "es" | "fr" | "ja" | "pt";
};

export const languages: Language[] = [
  { text: "Deutsch", value: "de" },
  { text: "English", value: "en" },
  { text: "español", value: "es" },
  { text: "français", value: "fr" },
  { text: "日本語", value: "ja" },
  { text: "Português", value: "pt" }
];
export default languages;
