export type Locale = {
  text: typeof LOCALES[number]["text"];
  value: typeof LOCALES[number]["value"];
};

export const LOCALES = [
  { text: "German (Germany)", value: "de_DE" },
  { text: "English (United States)", value: "en_US" },
  { text: "English (United Kingdom)", value: "en_GB" },
  { text: "Spanish (Spain)", value: "es_ES" },
  { text: "French (France)", value: "fr_FR" },
  { text: "Japanese (Japan)", value: "ja_JP" },
  { text: "Portuguese (Brazil)", value: "pt_BR" }
] as const;

export const locales: Locale[] = [...LOCALES];

export default locales;
