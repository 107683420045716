var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from "react";
var useSelection = function (identifier, initialItems) {
    if (identifier === void 0) { identifier = "id"; }
    if (initialItems === void 0) { initialItems = []; }
    var _a = useState(initialItems), selectedItems = _a[0], setSelectedItems = _a[1];
    var getItemIndex = function (newItem) {
        var propsToCheck = Array.isArray(identifier) ? identifier : [identifier];
        if (!propsToCheck.every(function (prop) { return newItem[prop]; })) {
            return -1;
        }
        return selectedItems.findIndex(function (item) {
            return propsToCheck.every(function (prop) { return item[prop] === newItem[prop]; });
        });
    };
    var toggleSelection = function (newItem, isDedup) {
        if (isDedup === void 0) { isDedup = true; }
        if (!newItem) {
            return;
        }
        var index = getItemIndex(newItem);
        if (index !== -1) {
            var updatedItems = __spreadArray([], selectedItems, true);
            if (isDedup) {
                updatedItems.splice(index, 1);
                setSelectedItems(updatedItems);
                return;
            }
            var overrideItems = updatedItems.map(function (item, i) {
                if (i === index) {
                    return __assign({}, newItem);
                }
                return item;
            });
            setSelectedItems(overrideItems);
            return;
        }
        setSelectedItems(__spreadArray(__spreadArray([], selectedItems, true), [newItem], false));
    };
    var clearSelection = function () {
        setSelectedItems([]);
    };
    var setSelections = function (newItem) {
        setSelectedItems(newItem);
    };
    var removeSelection = function (newItem) {
        var index = getItemIndex(newItem);
        if (index !== -1) {
            var updatedItems = __spreadArray([], selectedItems, true);
            updatedItems.splice(index, 1);
            setSelectedItems(updatedItems);
        }
    };
    return [
        selectedItems,
        toggleSelection,
        clearSelection,
        setSelections,
        removeSelection
    ];
};
export default useSelection;
