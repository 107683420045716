import { Layout, enums } from "@cheetahdigital/console-kit-cx";
import { AxiosError } from "axios";
import AuthContainer from "components/AuthContainer";
import { RequestErrorResponse } from "components/types";
import { useTranslation } from "locales/i18n";
import { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from "react";
import LoginServices from "services/login.service";
import {
  Button,
  Headline,
  Input,
  Message,
  Image,
  useLocalStorage
} from "shared";
import Captcha, { CaptchaRef } from "shared/dist/components/Captcha";
import useMessage from "utils/hooks/useMessage";

import styles from "./index.module.scss";

const LoginFailureChallenge: FC = () => {
  const [t] = useTranslation();
  const query = useMemo(() => new URLSearchParams(location.search), []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email] = useState(query.get("email") || "");
  const [value, setValue] = useState("");
  const landingUrl = query.get("landingUrl") || undefined;
  const [code, setCode] = useState("");
  const captchaRef = useRef<CaptchaRef>(null);
  const [isPasswordLessEmailSent, setIsPasswordLessEmailSent] = useLocalStorage(
    "isPasswordLessEmailSent",
    false
  );

  const { message, setSuccessMessage, setErrorMessage, clearMessage } =
    useMessage({
      description: {
        success: t("as-password-based-login-is-currently-unavailable")
      }
    });

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue]
  );

  const handleCloseMessage = useCallback(() => {
    clearMessage();
  }, [clearMessage]);

  const handleSendLoginEmail = useCallback(async () => {
    setIsLoading(true);
    try {
      if (value === code) {
        await LoginServices.sendPasswordlessWhileError(email, landingUrl);
        setSuccessMessage();
        setIsPasswordLessEmailSent(true);
        return;
      }

      setErrorMessage({
        description: "Captcha Not Matched. Please try again."
      });
      captchaRef.current?.refresh();
    } catch (err) {
      const error = err as AxiosError<RequestErrorResponse>;
      setErrorMessage({ description: error.response?.data.errorMessage });
    } finally {
      setIsLoading(false);
    }

    setValue("");
  }, [
    code,
    email,
    landingUrl,
    setErrorMessage,
    setIsPasswordLessEmailSent,
    setSuccessMessage,
    value
  ]);

  return (
    <>
      {message && (
        <Message
          title={message.title}
          description={message.description}
          isShow={!!message.type}
          type={message.type}
          onClose={handleCloseMessage}
        />
      )}
      <AuthContainer>
        {isPasswordLessEmailSent && (
          <Layout>
            <Layout.Row>
              <Headline isUppercase={false}>{t("check-your-email")}</Headline>
            </Layout.Row>

            <Layout.Row>
              <p>
                {t("we-and-apos-ve-sent-you-an-email-with-a-one-time-link")}
              </p>
            </Layout.Row>

            <Layout.Row
              columnStyles={{ textAlign: enums.textAlignments.CENTER }}
            >
              <div className={styles.icon}>
                <Image
                  src="/assets/images/check-email.svg"
                  height="160"
                  width="160"
                />
              </div>
            </Layout.Row>
          </Layout>
        )}
        {!isPasswordLessEmailSent && (
          <Layout>
            <Layout.Row>
              <p>
                We noticed that you are facing an issue during login. Please
                type the characters below to receive <b>one time login url</b>.
              </p>
            </Layout.Row>
            <Layout.Row>
              <Captcha onChange={c => setCode(c)} ref={captchaRef} />
            </Layout.Row>
            <Layout.Row>
              <Input
                value={value}
                onChange={handleChangeInput}
                placeholder="Enter the text you see above"
              />
            </Layout.Row>
            <Layout.Row>
              <Button
                isFluid
                onClick={handleSendLoginEmail}
                isDisabled={!value}
                isLoading={isLoading}
              >
                {t("send")}
              </Button>
            </Layout.Row>
          </Layout>
        )}
      </AuthContainer>
    </>
  );
};

export default LoginFailureChallenge;
