var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MultiSelect as CKMultiSelect } from "@cheetahdigital/console-kit-cx";
import styles from "./index.module.scss";
var MultiSelect = function (_a) {
    var label = _a.label, values = _a.values, options = _a.options, error = _a.error, placeholder = _a.placeholder, onChange = _a.onChange, disabled = _a.disabled, showClearIcon = _a.showClearIcon, _b = _a.isSearchable, isSearchable = _b === void 0 ? false : _b, isLoading = _a.isLoading;
    return (_jsx("div", __assign({ className: styles.multiSelect }, { children: _jsx(CKMultiSelect, { label: label, value: values, options: options, placeholder: placeholder, search: isSearchable, isDisabled: disabled, isError: !!error, errorMessage: error, showClearIcon: showClearIcon, isFluid: true, isLoading: isLoading, onChange: function (event, data) {
                if (data.value) {
                    onChange(data.value);
                }
            } }) })));
};
export default MultiSelect;
