var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from "../..";
import styles from "./index.module.scss";
var DateToLocale = function (_a) {
    var date = _a.date, isNoTooltip = _a.isNoTooltip, locale = _a.locale, timezone = _a.timezone;
    var correctLocale = locale === null || locale === void 0 ? void 0 : locale.replace("_", "-");
    var display = new Date(date).toLocaleString(correctLocale, {
        timeZone: timezone
    });
    return (_jsxs("span", __assign({ className: styles.tooltip }, { children: [isNoTooltip && _jsx(_Fragment, { children: display }), !isNoTooltip && (_jsx(Tooltip, __assign({ content: display, position: "bottom center", isGapless: true }, { children: _jsx("div", __assign({ className: styles.isEllipsis }, { children: display })) })))] })));
};
export default DateToLocale;
