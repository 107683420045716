var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Message as CKMessage } from "@cheetahdigital/console-kit-cx";
import classNames from "classnames";
import { useMemo } from "react";
import styles from "./index.module.scss";
var DEFAULT_DURATION = 3000;
var AUTOCLOSE_DURATION = 3000;
var Message = function (_a) {
    var title = _a.title, description = _a.description, isShow = _a.isShow, isAutoClose = _a.isAutoClose, duration = _a.duration, type = _a.type, onClose = _a.onClose;
    var messageType = type === "error" ? "warning" : type;
    var autoClose = useMemo(function () {
        if (isAutoClose === undefined)
            return type !== "error";
        return !!isAutoClose;
    }, [isAutoClose, type]);
    return (_jsx("div", __assign({ className: classNames(styles.message) }, { children: _jsx(CKMessage, __assign({ isDisplayed: isShow, onClose: onClose, duration: duration || DEFAULT_DURATION, autoClose: autoClose && AUTOCLOSE_DURATION, messageType: messageType, isGrowler: true }, { children: _jsxs("div", { children: [_jsx("p", { children: _jsx("b", { children: title }) }), _jsx("p", { children: description })] }) })) })));
};
export default Message;
