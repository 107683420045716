var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@cheetahdigital/console-kit-cx";
var Buttons = function (_a) {
    var children = _a.children, _b = _a.spacing, spacing = _b === void 0 ? "medium" : _b;
    return (_jsx(Button.Group, __assign({ layout: "horizontal", spacing: spacing }, { children: children })));
};
export default Buttons;
