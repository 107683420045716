import { jsx as _jsx } from "react/jsx-runtime";
import { Slider as CKSlider } from "@cheetahdigital/console-kit-cx";
import { useCallback } from "react";
var Slider = function (_a) {
    var label = _a.label, value = _a.value, minValue = _a.minValue, maxValue = _a.maxValue, stepSize = _a.stepSize, onChange = _a.onChange;
    var handleChange = useCallback(function (event, _a) {
        var value = _a.value;
        onChange(value);
    }, [onChange]);
    return (_jsx(CKSlider, { label: label, value: value, minValue: minValue, maxValue: maxValue, stepSize: stepSize, onChange: handleChange }));
};
export default Slider;
